<template>
  <!-- Add loading overlay -->
  <div v-if="initialLoading" class="loading-overlay">
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
    <div class="loading-text mt-4">Lädt Packliste...</div>
  </div>

  <div class="packlist" v-else-if="currentItem">
    <!-- Add loading bar -->
    <div class="loading-bar" :class="{ 'loading': isLoading }"></div>

    <!-- Header -->
    <transition name="slide-down">
      <div class="menu-header" v-if="showHeader">
        <Header />
      </div>
    </transition>

    <div class="packlist-header">
      <div class="d-flex overflow-x-auto">
        <div 
          v-for="(item, index) in openItems" 
          :key="index" 
          class="list-item flex-shrink-0"
          :class="{
            'grey': item.orderId === currentOrderId,
          }"
          @click="handleItemClick(item)"
        >
          <span>{{ index + 1 }}</span>
          <img :src="primeLogo" class="prime-logo" alt="Prime Logo" v-if="item.isPrime">
        </div>  
        <div 
          v-for="(item, index) in pausedItems" 
          :key="index" 
          class="list-item flex-shrink-0 warning"
          :class="{
            'warning': item.orderId === currentOrderId
          }"
          @click="handleItemClick(item)"
        >
          <span>{{ index + 1 }}</span>
        </div>
        <div 
          v-for="(item, index) in packedItems" 
          :key="index" 
          class="list-item flex-shrink-0 success" 
          @click="handleItemClick(item)"
        >
          <v-icon>mdi-check-circle</v-icon>
        </div>  
      </div>

      <!-- Order Items Count-->
      <div class="pa-2 d-flex justify-space-between align-center" style="gap: 16px;">
        <div>
          <v-chip 
            v-if="currentItem" 
            :color="currentPackstats.progress > currentPackstats.targetProgress ? 'error' : 'warning'" 
            size="small" 
            class="text-caption"
            :class="{ 'blink': currentPackstats.progress > currentPackstats.targetProgress }"
          >
            <v-icon small>mdi-clock</v-icon>
            {{ currentPackstats.progress }}s / {{ currentPackstats.targetProgress }}s
            <span class="time-divider mx-1">|</span>
            <span>⌀ {{ Math.round(packStats.time) }}s</span>
            <span class="time-count">({{ packStats.count }})</span>
          </v-chip>
        </div>
        <div class="d-flex align-center justify-end" style="gap: 8px;">
          <v-chip v-if="currentItem.warranty" color="warning" class="text-caption" small>
            Reklamation: {{ currentItem.warrantyReason }}
          </v-chip>
          <v-chip class="text-caption" color="secondary" small>
            {{ currentOrderItemsCount }} Artikel
          </v-chip>
          <v-btn
            color="primary"
            elevation="2"
            small
            @click="handlePrinterSelection"
          >
            <v-icon small left>mdi-printer</v-icon>
            {{ currentPrinter ? currentPrinter.name : 'Drucker auswählen' }}
          </v-btn> 
          <v-btn
            color="primary"
            elevation="2"
            small
            @click="handlePausePacklistItem"
            v-if="isDuplicatedPacklistItem(currentOrderId)"
          >
            <v-icon small left>mdi-pause</v-icon>
            Nach hinten schieben
          </v-btn>
        </div>
      </div>
      <!-- Product List / Upsell List -->
      <div class="d-flex gap-4 justify-space-between pa-3">
        <!-- Products Section (Left) -->
        <div class="d-flex gap-4 products-upsell-list">
          <div class="packstats">
            <div 
              class="packstats-progress" 
              :style="{ 
                height: `${Math.min((currentPackstats.progress / currentPackstats.targetProgress) * 100, 100)}%`, 
                backgroundColor: currentPackstats.progress > currentPackstats.targetProgress ? 'red' : 'green' 
              }"
            ></div>
          </div>
          <v-card
            v-for="product in upsellProducts"
            :key="product.sku"
            class="product-card pa-1 flex-shrink-1"
            :style="{ width: `${100 / Math.min(upsellProducts.length, 8)}%`, aspectRatio: '1/1' }"
            elevation="2"
          >
            <v-img
              :src="`/api/files/sku?sku=${product.sku}`"
              contain
              class="product-image"
              height="100%"
              width="100%"
            >
            <v-badge
              v-if="product.qty > 1"
              :content="`Menge: ${product.qty}`"
              color="error"
              class="quantity-badge"
              location="top end"
              offset-x="-4"
              offset-y="8"
            ></v-badge>
          </v-img>  
          </v-card>
          <v-card 
            v-if="currentFyler"
            class="product-card pa-1 flex-shrink-0"
            :style="{ width: `${100 / Math.min(upsellProducts.length, 8)}%`, aspectRatio: '1/1', backgroundColor: currentFyler?.bg, color: currentFyler?.color }"
            elevation="2"
            >

            <v-img
              :src="currentFyler.url"
              contain
              height="100%"
              width="100%"
            ></v-img>
          </v-card>
        </div>

        <!-- Address Section (Right) -->
        <div class="address-section">
          <v-card elevation="2" class="h-100 grey lighten-4">
            <v-card-text class="text-subtitle-1 pa-2">
              <div class="customer-name text-truncate green--text text--darken-1 font-weight-bold">
                {{ currentItem.customer.firstName }} {{ currentItem.customer.lastName }}
              </div>
              <div class="customer-address text-truncate">
                {{ currentItem.customer.adress }}
              </div>
              <div v-if="currentItem.shipping?.type === 'PRIME'">
                <img class="w-50" :src="primeLogo" alt="Prime Logo">
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <!-- Product List -->
    <div class="product-list pa-2 pb-32" v-if="currentProducts.length > 0">
      <v-card
        v-for="(product, index) in currentProducts"
        :key="`${product.sku}_${index}`" 
        class="pa-3 light-blue lighten-5" 
        :class="{ 'marked': markedProducts.includes(`${product.sku}_${index}`) }"
      >
        <!-- SKU and Ready state section -->
        <div>
          <v-list class="light-blue lighten-5">
            <v-list-item 
                class="text-subtitle secondary"
                style="color: #f9f9f9;"
              >
                <v-list-item-title class="font-weight-bold" v-text="product.sku"></v-list-item-title>
                <v-list-item-subtitle class="text-body-2">
                  <div class="d-flex justify-end">
                    <v-chip label color="success" v-if="product.status === 'ready' && product.shelf">
                      <v-icon>mdi-check-circle</v-icon>
                      Bereit in Fach {{ product.shelf.slot }}
                    </v-chip>
                    <v-chip label color="success" v-else-if="product.status === 'ready'">
                      <v-icon>mdi-check-circle</v-icon>
                      Bereit 
                    </v-chip>
                    <v-chip label color="error" v-else>
                      <v-icon>mdi-close-circle</v-icon>
                      {{ product.status.charAt(0).toUpperCase() + product.status.slice(1) }} 
                    </v-chip>
                  </div>
                </v-list-item-subtitle>
              </v-list-item>
          </v-list>
        </div>
        
        <!-- Product content flex container -->
        <div class="d-flex justify-space-between ga-2 mt-2">
          <div class="product-image-container" :class="{ 'dark-mode': isDarkBackground }" v-if="product.designId">
            <v-img
              :src="getImageUrl(product.designId)"
              contain
              width="100%"
              class="product-design-image"
            ></v-img>
            <v-btn
              icon
              size="small"
              class="mode-toggle"
              @click="toggleBackgroundMode"
              :color="isDarkBackground ? 'white' : 'black'"
            >
              <v-icon>{{ isDarkBackground ? 'mdi-white-balance-sunny' : 'mdi-moon-waning-crescent' }}</v-icon>
            </v-btn>
          </div>
          <div class="product-attributes-container ps-4" :class="product.designId ? 'w-50' : 'w-100'">
            <v-list class="light-blue lighten-5">
              <v-list-item 
                v-if="product.qty > 1"
                style="padding: 0;"
              >
                <v-alert
                  color="error"
                  icon="mdi-alert-circle"
                  title="Achtung - Produkt mehrfach bestellt"
                  class="mt-2 w-100"
                  style="color: #f9f9f9;"
                >
                  <span style="font-weight: bold; font-size: 28px;">Menge: {{ product.qty }}</span>
                </v-alert>
              </v-list-item>
              <v-list-item 
                v-for="(value, key, index) in product.translated" 
                :key="index" 
                v-if="!value?.startsWith?.('http') && (key.includes('line') || key.includes('font') || key.includes('motiv'))"
                class="mb-1 "
                variant="elevated"
              >
                <v-list-item-title class="text-subtitle-2 font-weight-medium">{{ key }}</v-list-item-title>
                <v-list-item-subtitle class="text-body-2">{{ value }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item 
                style="padding: 0;"
              >
              <v-btn>
                <v-icon>mdi-refresh-circle</v-icon>
                In Produktion zurücksetzen              
              </v-btn>
              </v-list-item>
            </v-list>
            <!--<v-btn
              @click="toggleProductAsMarked(product, index)"
            >
              <v-icon>mdi-refresh-circle</v-icon>
              {{ markedProducts.includes(`${product.sku}_${index}`) ? 'Produkt zurücksetzen' : 'Produkt markieren' }}
            </v-btn>-->
          </div>
        </div>
      </v-card>
      <div ref="listEnd" class="list-end-marker"></div>
    </div>

    <!-- Package height selection -->
    <div class="height-selection-container">
      <div class="d-flex justify-center align-center py-4" style="gap: 16px;">
        <v-btn-toggle
          v-model="under3cm"
          mandatory
          dense
        >
          <v-btn small>
            <v-icon small>mdi-chevron-down</v-icon>
            Unter 3cm
          </v-btn>
          <v-btn small>
            <v-icon small>mdi-chevron-up</v-icon>
            Über 3cm
          </v-btn>
        </v-btn-toggle>

        <v-btn-toggle
          v-model="under8cm"
          mandatory
          dense
        >
          <v-btn small>
            <v-icon small>mdi-chevron-down</v-icon>
            Unter 8cm
          </v-btn>
          <v-btn small>
            <v-icon small>mdi-chevron-up</v-icon>
            Über 8cm
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <!-- Warenkorbregal Dialog -->
    <v-dialog v-model="showMultiCartDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 bg-warning">
          <v-icon class="mr-2">mdi-cart-arrow-down</v-icon>
          Warenkorbregal
        </v-card-title>
        
        <v-card-text class="pt-4">
          <p class="text-body-1 mb-4">
            Bitte wählen Sie das Warenkorbregal aus, in das die Bestellung gelegt werden soll.
          </p>
          
          <v-alert v-if="currentItem?.orderId" type="info" class="mt-4">
            <strong>Bestellnummer:</strong> {{ currentItem.orderId }}<br>
            <strong>Kunde:</strong> {{ currentItem.customer?.firstName }} {{ currentItem.customer?.lastName }}
          </v-alert>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey-darken-1" variant="text" @click="showMultiCartDialog = false">
            Abbrechen
          </v-btn>
          <v-btn 
            color="warning" 
            :disabled="!selectedCartShelf"
          >
            Bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Problem Dialog -->
    <v-dialog v-model="showProblemDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 bg-error text-white">
          <v-icon class="mr-2">mdi-alert-circle</v-icon>
          Problem melden
        </v-card-title>
        
        <v-card-text class="pt-4">
          <p class="text-body-1 mb-4">
            Bitte wählen Sie den Problemtyp aus und beschreiben Sie das Problem genauer.
          </p>
          
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedProblemType"
                label="Art des Problems"
                :items="problemTypes"
                variant="outlined"
                required
              ></v-select>
            </v-col>
            
            <v-col cols="12">
              <v-textarea
                v-model="problemDescription"
                label="Problembeschreibung"
                variant="outlined"
                rows="4"
                counter="500"
                :rules="[v => v.length <= 500 || 'Max 500 Zeichen']"
              ></v-textarea>
            </v-col>
          </v-row>
          
          <v-alert v-if="currentItem?.orderId" type="info" class="mt-4">
            <strong>Bestellnummer:</strong> {{ currentItem.orderId }}<br>
            <strong>Produkte:</strong> {{ currentOrderItemsCount }}
          </v-alert>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey-darken-1" variant="text" @click="showProblemDialog = false">
            Abbrechen
          </v-btn>
          <v-btn 
            color="error" 
            @click="reportProblem"
            :disabled="!selectedProblemType || !problemDescription"
          >
            Problem melden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Printers Dialog -->
    <v-dialog v-model="showPrintersDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Drucker & Paket Auswahl</v-card-title>
        <v-card-text>
          <!-- Printer Selection -->
          <v-list>
            <v-list-item
              v-for="printer in printers"
              :key="printer.url"
              @click="selectPrinter(printer)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ printer.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ printer.url }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="currentPrinter?.url === printer.url">mdi-check</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showPrintersDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="showMultiCartItemsDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 bg-warning">
          <v-icon class="mr-2">mdi-cart-arrow-down</v-icon>
          Es befinden sich Bestellungen im Warenkorbregal
        </v-card-title>

        <v-card-text class="pa-4">
          <div v-if="multiCartItems.length > 0">
            <v-card 
              v-for="item in multiCartItems" 
              :key="item.orderId"
              class="mb-4 order-card"
              variant="outlined"
              elevation="1"
            >
              <v-card-title class="d-flex align-center py-2 px-4 bg-secondary text-white">
                <v-icon class="mr-2">mdi-cart</v-icon>
                <span class="font-weight-bold">Bestellung #{{ item.orderId }}</span>
                <v-chip class="ml-auto" color="warning" size="small">
                  {{ item.products.length }} Artikel
                </v-chip>
              </v-card-title>
              
              <v-card-text class="px-0 py-2">
                <!-- Group products by slot for better organization -->
                <div 
                  v-for="(shelfGroup, slot) in groupProductsBySlot(item.products)" 
                  :key="slot"
                  class="pa-2"
                >
                  <v-sheet
                    class="px-3 py-2 mb-2 rounded d-flex align-center"
                    :color="getShelfColor(slot)"
                    dark
                  >
                    <v-icon class="mr-2">mdi-shelf</v-icon>
                    <span class="text-h6">Regal {{ slot }}</span>
                    <v-chip class="ml-auto" size="small" variant="outlined">
                      {{ shelfGroup.length }} Produkte
                    </v-chip>
                  </v-sheet>
                  
                  <v-list density="compact" class="products-list">
                    <v-list-item
                      v-for="(product, index) in shelfGroup"
                      :key="index"
                      class="product-item"
                      :class="{'bg-grey-lighten-4': index % 2 === 0}"
                    >
                      <template v-slot:prepend>
                        <v-avatar size="32" color="grey-lighten-3" class="mr-2">
                          <span class="text-caption">{{ index + 1 }}</span>
                        </v-avatar>
                      </template>
                      
                      <v-list-item-title class="d-flex align-center">
                        <span class="font-weight-bold">{{ product.qty }}x</span>
                        <span class="ml-2 text-truncate">{{ product.sku }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>
          </div>
          
          <v-alert
            v-else
            type="info"
            class="mt-4"
            text="Keine Produkte im Warenkorbregal gefunden."
          ></v-alert>
        </v-card-text>
        
        <v-card-actions class="pa-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            @click="showMultiCartItemsDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <transition name="fade-up">
      <div class="d-flex flex-column gap-2 pa-4 packlist-actions" 
      v-if="hasScrolledToBottom || !isListLongerThanViewport"
      >
        <!-- When Warenkorbregal button is needed -->
        <div v-if="isDuplicatedPacklistItem(currentOrderId)" class="d-flex justify-center button-container">
          <v-btn
            class="action-button"
            color="error"
            @click="showProblemDialog = true"
          >
            <v-icon>mdi-alert-circle</v-icon>
            Problem melden              
          </v-btn>
          
          <v-btn
            class="action-button"
            color="warning"
            @click="showMultiCartDialog = true"
            v-if="!isShippingReady"
          >
            <v-icon>mdi-cart-arrow-down</v-icon>
            Warenkorbregal              
          </v-btn>
          
          <v-btn
            class="action-button"
            color="primary"
            v-if="isShippingReady && !isPrinted"
            @click="print()"
            :disabled="isPrinting"
          >
            <v-icon>mdi-printer</v-icon>
            {{ !hasScrolledToBottom && isListLongerThanViewport ? 'Bitte scrollen Sie zum Ende der Liste' : 'Drucken' }}
          </v-btn>
          
          <v-btn
            class="action-button"
            color="success"
            v-if="isPrinted"
            @click="pack()"
          >
            <v-icon>mdi-check-circle</v-icon>
            Gepackt
          </v-btn>
        </div>

        <!-- When only Problem melden and Print/Pack buttons are needed (side by side) -->
        <div v-else class="d-flex justify-center button-container">
          <v-btn
            class="action-button"
            color="error"
            @click="showProblemDialog = true"
          >
            <v-icon>mdi-alert-circle</v-icon>
            Problem melden              
          </v-btn>
          
          <v-btn
            class="action-button"
            color="warning"
            @click="showMultiCartDialog = true"
            v-if="!isShippingReady"
          >
            <v-icon>mdi-cart-arrow-down</v-icon>
            Warenkorbregal              
          </v-btn>
          
          <v-btn
            class="action-button"
            color="primary"
            v-if="isShippingReady && !isPrinted"
            @click="print()"
            :disabled="isPrinting"
          >
            <v-icon>mdi-printer</v-icon>
            {{ !hasScrolledToBottom && isListLongerThanViewport ? 'Bitte scrollen Sie zum Ende der Liste' : 'Drucken' }}
          </v-btn>
          
          <v-btn
            class="action-button"
            color="success"
            v-if="isPrinted"
            @click="pack()"
          >
            <v-icon>mdi-check-circle</v-icon>
            Gepackt
          </v-btn>
        </div>
      </div>
    </transition>

    <transition name="fade-slide">
      <div class="scroll-indicator" v-if="!hasScrolledToBottom && isListLongerThanViewport">
        <v-icon>mdi-arrow-down</v-icon>
      </div>
    </transition>
  </div>
</template>

<script>
import packlistService from '@/services/PacklistService';
import Header from "../header";
import primeLogo from '@/assets/prime.png';

export default {
  name: 'PacklistItemsV5',
  data() {
    return {
        currentOrderId: null,
        currentPacklistItemId: null,
        currentItem: null,
        isPrinting: false,
        isPrinted: false,
        currentPrinter: null,
        flyerMapping: [],
        SkuBlackList: [],
        showMultiCartDialog: false,
        showProblemDialog: false,
        showPrintersDialog: false,
        showMultiCartItemsDialog: false,
        items: [],
        multiCartItems: [],
        printers: [],
        primeLogo,
        isDarkBackground: false,
        currentPackstats: {
          progress: 0,
          targetProgress: 40
        },
        selectedCartShelf: null,
        selectedProblemType: null,
        problemDescription: '',
        problemTypes: [
          'Fehlende Produkte',
          'Beschädigte Produkte',
          'Falsche Produkte',
          'Druckfehler',
          'Systemfehler',
          'Sonstiges'
        ],
        markedProducts: [],
        hasScrolledToBottom: false,
        hasScrolledToTop: false,
        isListLongerThanViewport: false,
        listEndObserver: null,
        packStats: { time: 0, count: 0 },
        packStatsInterval: null,
        isLoading: false,
        under3cm: false,
        under8cm: false,
        showHeader: false,
        lastScrollPosition: 0,
        headerTimeout: null,
        initialLoading: true,
    }
  },
  components: {
    Header,
    primeLogo
  },
  methods: {
    async getPacklistItems(name) {
        let response = await packlistService.getPacklistItems(name);
        this.items = response.data;
    },
    async getPacklistMultiCartItems(name) {
      let response = await packlistService.getPacklistMultiCartItems(name);

      this.multiCartItems = response.data;

      if (this.multiCartItems.length > 0) {
        this.showMultiCartItemsDialog = true;
      }
    },
    async getItemInOrder(orderId) {
        let response = await packlistService.getPacklistItem(orderId);

        return response.data;
    },
    getImageUrl(designId) {
        return `https://accelery-poster.s3.eu-central-1.amazonaws.com/${
        encodeURIComponent(designId)
        }.png`;
    },
    async getPrinterList() {
      let response = await packlistService.getPrinters();
      this.printers = response.data;
    },
    async print() {
      this.isPrinting = true;
      let printer = this.$cookies.get('printer');

      if (!printer) {
        this.showPrinterSelectionDialog = true;
        return;
      }

      let response = await packlistService.print(
        this.currentItem.orderId, 
        printer.url, 
        this.under3cm 
      );
      this.isPrinting = false;
      this.isPrinted = true;
      
      return response.data;
    },
    async pack() {
      try {
        let response = await packlistService.trackEnd(this.currentPacklistItemId);

        if (response.status === 200) {
            this.saveLastTimestamp();
            
            // Refresh the packlist items data
            const packlistName = this.$route.params.id;
            await this.getPacklistItems(packlistName);
            
            // Then move to next item
            this.next();
        }
      } catch (error) {
        console.error('Error packing item:', error);
      }
    },
    async sendFeedback(orderId) {
      let note = this.selectedProblemType + ": " + this.problemDescription;

      let response = await packlistService.sendFeedback(orderId, note);
      return response.data;
    },
    buildFlyerMapping() {
      this.flyerMapping = [
      {
          value: 'GF',
          label: 'Flyer',
          bg: '#d45355',
          color: '#f9f9f9',
          url: 'https://geschenkfreude.com/cdn/shop/t/19/assets/geschenkfreude-logo.svg?v=152746364003842334961738143808'
        },
        {
          value: 'MM',
          label: 'Flyer',
          bg: '#d0e5de',
          color: '#121212',
          url: 'https://muckelmeister.de/cdn/shop/t/60/assets/logo-white-border.webp?v=4545666206715883731738916651'
        },
        {
          value: '#BP',
          label: 'Flyer',
          bg: '#EBE2D2',
          color: '#121212',
          url: 'https://boandbirdie.com/cdn/shop/files/Bo_Birdie_Lockup_Black.png?v=1714989905&width=1368'
        },
        {
          value: '#FW',
          label: 'Flyer',
          bg: '#E17D63',
          color: '#f9f9f9',
          url: 'https://famwalls.com/cdn/shop/files/famwalls_test_logo.png?v=1637591278&width=200'
        }
      ]
    },
    buildSkuBlackList() {
      this.SkuBlackList = [
        "LYTO_TYPE_C_KABEL",
        "LYTO_NETZSTECKER",
        "LYTO_LED_SOCKEL_WHITE",
        "wooden_led_base_HV",
        "wooden_led_base_RGB",
        "NETZSTECKER",
        "TYP_C_USB_KABEL"
      ];
    },
    async handleItemClick(item) {
      this.isLoading = true;
      this.currentOrderId = item.orderId;
      this.currentPacklistItemId = item.id;
      this.currentPackstats.progress = 0;
      // Increased delay to match animation
      setTimeout(() => {
        this.isLoading = false;
      }, 400);
    },
    toggleBackgroundMode() {
      this.isDarkBackground = !this.isDarkBackground;
    },
    toggleProductAsMarked(product, index) {
      // Create a unique identifier for this specific product
      const productId = `${product.sku}_${index}`;
      
      if (this.markedProducts.includes(productId)) {
        // If already marked, remove from marked products
        this.markedProducts.splice(this.markedProducts.indexOf(productId), 1);
      } else {
        // If not marked, add to marked products
        this.markedProducts.push(productId);
      }
    },
    async reportProblem() {
      // Handle problem report submission
      try {
        await this.sendFeedback(this.currentItem.orderId);
        // Reset and close dialog
        this.showProblemDialog = false;
        this.selectedProblemType = null;
        this.problemDescription = '';
        
        // Show success message
        this.$nextTick(() => {
          this.$emit('show-toast', {
            text: 'Problem erfolgreich gemeldet',
            color: 'success'
          });
        });
      } catch (error) {
        console.error('Error reporting problem:', error);
      }
    },
    setupScrollObserver() {
      if (!this.currentProducts.length) return;
      
      // Check if the list is longer than the viewport
      this.$nextTick(() => {
        const listHeight = this.$refs.listEnd?.getBoundingClientRect().top - this.$el.getBoundingClientRect().top;
        this.isListLongerThanViewport = listHeight > window.innerHeight;
        
        // If it's not longer, no need to require scrolling
        if (!this.isListLongerThanViewport) {
          this.hasScrolledToBottom = true;
          return;
        }
        
        // Set up the observer
        this.listEndObserver = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            this.hasScrolledToBottom = true;
            this.listEndObserver.disconnect();
          }
        }, {
          rootMargin: '0px 0px 0px 0px'
        });
        
        // Start observing the end marker
        if (this.$refs.listEnd) {
          this.listEndObserver.observe(this.$refs.listEnd);
        }
      });
    },
    async handlePrinterSelection() {
      await this.getPrinterList();

      this.showPrintersDialog = true;
    },
    groupProductsBySlot(products) {
      const groups = {};
      
      for (const product of products) {
        const slot = product.slot || 'Unbekannt';
        
        if (!groups[slot]) {
          groups[slot] = [];
        }
        
        groups[slot].push(product);
      }
      
      return groups;
    },
    getShelfColor(slot) {
      // Generate consistent colors based on shelf location
      const shelfColors = {
        'A': 'blue darken-2',
        'B': 'green darken-2',
        'C': 'purple darken-2',
        'D': 'orange darken-2'
      };
      
      // Get color based on first letter of slot, default to deep-orange
      const firstLetter = slot.charAt(0).toUpperCase();
      return shelfColors[firstLetter] || 'deep-orange darken-2';
    },
    selectPrinter(printer) {
      this.$cookies.set('printer', printer);

      this.currentPrinter = printer;
      this.showPrintersDialog = false;
    },
    saveLastTimestamp() {
        localStorage.setItem("lastTimestamp", new Date().toISOString());
    },

    getLastTimestamp() {
        const lastTimestamp = localStorage.getItem("lastTimestamp");
        if (lastTimestamp) {
            return new Date(lastTimestamp);
        }
        this.saveLastTimestamp();
        return new Date();
    },
    async loadPackStats() {
        try {
            const response = await packlistService.getPackStats();
            if (response?.data) {
                this.packStats = response.data;
            }
        } catch (error) {
            console.error('Error loading pack stats:', error);
        }
    },
    next() {
      const currentIndex = this.items.findIndex(item => item.orderId === this.currentOrderId);
      if (currentIndex < this.items.length - 1) {
        const nextItem = this.items[currentIndex + 1];
        this.handleItemClick(nextItem);
        this.currentPackstats.progress = 0;
      }

      this.isPrinted = false;
      this.isPrinting = false;
    },

    prev() {
      const currentIndex = this.items.findIndex(item => item.orderId === this.currentOrderId);
      if (currentIndex > 0) {
        const prevItem = this.items[currentIndex - 1];
        this.handleItemClick(prevItem);
        this.currentPackstats.progress = 0;
      }

      this.isPrinted = false;
      this.isPrinting = false;
    },

    handleKeyPress(e) {
      // Don't handle keys if a dialog is open
      if (this.showFeedbackDialog || this.showPrinterSelectionDialog || 
          this.showMultiCartDialog || this.showProblemDialog) {
        return;
      }

      switch(e.key) {
        case 'ArrowRight':
        case 'n':
          this.next();
          break;
        case 'ArrowLeft':
        case 'p':
          this.prev();
          break;
        case 'a':
          this.under3cm = !this.under3cm;
          break;
        case 'b':
          if (this.workflow.canPrint && !this.printing) {
            this.print();
          }
          break;
        case 'c':
          if (this.workflow.canPack) {
            this.pack();
          }
          break;
      }
    },
    handleScroll() {
        const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        
        // Clear any existing timeout
        if (this.headerTimeout) {
            clearTimeout(this.headerTimeout);
        }
        
        // Only show header when deliberately scrolling past top (negative scroll)
        if (currentScroll <= -50) {  // Require a significant "pull" gesture
            if (!this.showHeader) {  // Only set timeout if header isn't already shown
                this.headerTimeout = setTimeout(() => {
                    this.showHeader = true;
                }, 200);
            }
        } else if (currentScroll > 0) {  // Hide when scrolling down
            this.showHeader = false;
        }
        
        this.lastScrollPosition = currentScroll;
    },
    async handlePausePacklistItem() {
      let response = await packlistService.pausePacklistItem(this.currentPacklistItemId);
      
      if (response.status === 200) {
        await this.getPacklistItems(this.$route.params.id);
        this.next();
      }
    },
    isDuplicatedPacklistItem(orderId) {
      let duplicated = this.items.filter(i => i.orderId === orderId);
      return duplicated.length > 1;
    }
  },
  computed: {
    packedItems() {
        return this.items.filter(item => item.status === 'packed');
    },
    openItems() {
        return this.items.filter(item => item.status === 'open');
    },
    pausedItems() {
      return this.items.filter(item => item.status === 'paused');
    },
    currentProducts() {
      if (!this.currentItem?.products) return [];

      return this.currentItem.products.filter(product => {
        if (this.SkuBlackList.includes(product.sku)) {
          return false;
        }
        
        return product.upsell === false || product.upsell === undefined;
      });
    },
    upsellProducts() {
      if (!this.currentItem?.products) return [];
      
      const upsellItems = this.currentItem.products.filter(
        product => product.upsell === true || this.SkuBlackList.includes(product.sku)
      );

      const duplicatedProducts = upsellItems.reduce((acc, product) => {
        const existingProduct = acc.find(p => p.sku === product.sku);
        
        if (existingProduct) {
          existingProduct.qty = (existingProduct.qty || 1) + (product.qty || 1);
        } else {
          acc.push({
            ...product,
            qty: product.qty || 1
          });
        }
        
        return acc;
      }, []);

      return duplicatedProducts;
    },
    currentOrderItemsCount() {
      return this.currentItem.products?.length || 0;
    },
    currentFyler() {
      if (!this.currentItem) return null;
      
      const orderId = this.currentItem.orderId;
      const reference = this.currentItem.reference;
      let value = null;

      if (this.flyerMapping.length === 0) {
        this.buildFlyerMapping();
      }

      if (!reference && orderId) {
        if (orderId.toString().startsWith('MM')) {
          value = this.flyerMapping.find(f => f.value === 'MM');
        } else {
          value = this.flyerMapping.find(f => f.value === 'GF');
        }
      }

      // If external order -> check for reference
      if (reference) {
        if (reference.toString().startsWith('#BP')) {
          value = this.flyerMapping.find(f => f.value === '#BP');
        } else if (reference.toString().startsWith('#FW')) {
          value = this.flyerMapping.find(f => f.value === '#FW');
        }
      }

      return value;
    },
    isShippingReady() {
      if (!this.currentItem) return false;

      // Get all products that need to be ready (not blacklisted and not upsells)
      const requiredProducts = this.currentProducts.filter(product => 
        !this.SkuBlackList.includes(product.sku) && !product.upsell
      );
      
      // If there are no required products, we can't ship
      if (requiredProducts.length === 0) return false;
      
      // Check if ALL required products are ready
      const allProductsReady = requiredProducts.every(product => product.status === 'ready');

      return allProductsReady;
    }
  },
  async mounted() {
    try {
      let printer = this.$cookies.get('printer');

      if (printer) {
        this.currentPrinter = printer;
      } else {
        this.showPrinterSelectionDialog = true;
      }

      let packlistName = this.$route.params.id;

      // Load initial data
      await Promise.all([
        this.getPacklistItems(packlistName),
        this.getPacklistMultiCartItems(packlistName),
        this.loadPackStats()
      ]);

      if (this.items.length > 0) {
        this.currentOrderId = this.items.find(i => i.status === 'open').orderId;
        this.currentPacklistItemId = this.items.find(i => i.orderId === this.currentOrderId).id;
      }

      // Keep your original progress tracking
      setInterval(() => {
        this.currentPackstats.progress++;
      }, 1000);

      // Add interval for pack stats refresh
      this.packStatsInterval = setInterval(() => this.loadPackStats(), 10000);

      //Set darkmode to true if packlist includes printcut or florithings
      if (packlistName.includes('florithings') || packlistName.includes('printcut')) {
        this.isDarkBackground = true;
      }

      // Set up observer when component is mounted
      this.$nextTick(() => {
        this.setupScrollObserver();
      });

      // Add keydown event listener
      window.addEventListener('keydown', this.handleKeyPress);

      // Add scroll event listener
      window.addEventListener('scroll', this.handleScroll);
    } finally {
      this.initialLoading = false;
    }
  },
  created() {
    this.buildFlyerMapping();
    this.buildSkuBlackList();
    window.addEventListener('keydown', this.handleKeyPress);
  },
  watch: {
    currentOrderId: {
      immediate: true,
      async handler(newOrderId) {
        if (newOrderId) {
          try {
            const orderDetails = await this.getItemInOrder(newOrderId);
            this.currentItem = orderDetails;
            // Initialize both height selections based on carton properties
            this.under3cm = this.currentItem?.carton?.under3cm || false;
            this.under8cm = this.currentItem?.carton?.under8cm || false;
          } catch (error) {
            console.error('Error fetching order details:', error);
          }
        }
      },
      openItems: {
        handler() {
          if (this.openItems.length === 0) {
            this.$router.push(`/`);
          }
        }
      }
    },
    currentProducts: {
      handler() {
        this.hasScrolledToBottom = false;
        this.hasScrolledToTop = false;

        if (this.listEndObserver) {
          this.listEndObserver.disconnect();
        }
        this.$nextTick(() => {
          this.setupScrollObserver();
        });
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
    clearInterval(this.packStatsInterval);
    window.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('scroll', this.handleScroll);
    if (this.headerTimeout) {
        clearTimeout(this.headerTimeout);
    }
  }
}
</script>

<style scoped>
.packlist-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.list-item {
  position: relative;
  width: 80px;
  height: 40px;
  min-width: 80px;
  flex-shrink: 0;
  aspect-ratio: 1;
  box-shadow: inset -4px 0 5px -2px rgba(0, 0, 0, 0.2);
}

.prime-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.product-card {
  transition: transform 0.2s ease;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
}

.product-card:hover {
  transform: scale(1.05);
}

.gap-4 {
  gap: 16px;
}

.product-image {
  object-fit: contain;
  background-color: white;
  height: 100%;
  width: 100%;
}

.address-section {
  background-color: #eee;
  width: 20%;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.products-upsell-list {
  min-width: 0;
  flex-wrap: nowrap;
  width: 80%;
}

.product-card {
  min-width: 80px;
  max-width: 120px;
  position: relative;
  aspect-ratio: 1/1;
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 131px !important;
}

@media screen and (min-width: 1336px) {
  .product-list {
    max-width: 800px;
    margin: 0 auto;
  }
}

.quantity-badge :deep(.v-badge__content) {
  font-size: 12px;
  padding: 4px 8px;
  min-width: auto;
  height: auto;
}

.product-image-container {
  position: relative;
  background: #f9f9f9;
  border-radius: 4px;
  width: 50%;
  padding: 8px;
}

@media screen and (min-width: 1336px) {
  .product-image-container {
    width: 30%;
  }
}

.product-image-container.dark-mode {
  background: #121212;
}

.product-attributes-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.product-design-image {
  z-index: 1;
}

.mode-toggle {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 1;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.mode-toggle:hover {
  opacity: 1;
}

.button-container {
  gap: 16px;
}

.packlist-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white;
  z-index: 2;
  border-top: 1px solid #e0e0e0;
}

.action-button {
  width: calc(50% - 8px);
  margin: 0;
}

@supports not (gap: 16px) {
  .action-button + .action-button {
    margin-left: 16px;
  }
}

.packstats {
  position: relative;
  height: 100%;
  width: 20px;
  background-color: grey;
}

.packstats-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 100%;
  background-color: green;
}

.packstats-progress-bar.alert {
  background-color: red;
}

.spacer {
  width: 100%;
  margin: 8px 0;
  opacity: 0;
  display: block;
}

.marked {
  background-color: #86D2DC;
  transition: background-color 0.2s ease;
}

.list-end-marker {
  height: 1px;
  margin-bottom: 30px;
  /* The marker is invisible */
  opacity: 0;
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* Add space for the fixed bottom actions */
  padding-bottom: 131px !important;
}

.scroll-indicator {
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(33, 150, 243, 0.9);
  color: white;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 1.5s infinite ease-in-out;
}

.scroll-indicator .v-icon {
  display: block;
  margin: 0 auto;
  animation: bounce 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 150, 243, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Transition for scroll indicator */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px) translateX(-50%);
}

/* Transition for action menu */
.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* Make sure the action buttons container doesn't disappear abruptly */
.packlist-actions {
  transition: all 0.3s ease;
}

/* Smooth background transition for marked products */
.marked {
  transition: background-color 0.3s ease;
}

.customer-name, .customer-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.customer-name {
  margin-bottom: 4px;
}

.time-divider {
    opacity: 0.7;
}

.time-count {
    font-size: 0.9em;
    opacity: 0.8;
    margin-left: 4px;
}

.blink {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: transparent;
  z-index: 1000;
  transform: scaleX(0);
  transform-origin: left;
}

.loading-bar.loading {
  background: #2196F3;
  box-shadow: 0 0 10px rgba(33, 150, 243, 0.7);
  animation: loading 0.4s ease-in-out;
}

@keyframes loading {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  50% {
    transform: scaleX(0.6);
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

.package-height-selection {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #f5f5f5;
}

.v-btn-toggle {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.menu-header {
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
    margin-bottom: 8px;  /* Add some space below the header */
}

.slide-down-enter-active {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    max-height: 200px;
}

.slide-down-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    max-height: 200px;
}

.slide-down-enter-from,
.slide-down-leave-to {
    max-height: 0;
    opacity: 0;
    transform: translateY(-10px);
}

/* Add a transition for opacity on the header content */
.menu-header :deep(.header-content) {
    transition: opacity 0.3s ease;
}

.packlist {
    overscroll-behavior-y: contain;
    height: 100vh;
    overflow-y: auto;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-text {
  color: #1976D2;
  font-size: 1.2rem;
  font-weight: 500;
}
</style>